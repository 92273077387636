import { Col, Form, Input, Modal, Row } from "antd";
import GooglePlaceAutoComplete from "../GooglePlacesAutoComplete/GooglePlaceAutoComplete.view";
import { FaLocationDot } from "react-icons/fa6";
import { useState } from "react";
import {
  useGetAddressFromLatLng,
  useGetCoordinatesFromAddress,
} from "../../../api/mutations/useGoogleApi";
import MapPicker from "../../MapPicker";
import { FaWindowClose } from "react-icons/fa";

const AddressSelectionInput = ({
  form,
  onChange,
  addressName = "address",
  gpsName = "gps",
  onClickLocation,
} = {}) => {
  const [enteringAddress, setEnteringAddress] = useState(false);
  const [enteringGps, setEnteringGps] = useState(false);
  const [mapModalVisible, setMapModalVisible] = useState(false);

  const [gps, setGps] = useState();
  const [address, setAddress] = useState();

  const { mutate: getCoordinatesFromAddress, isPending: isCoordinatesPending } =
    useGetCoordinatesFromAddress({
      onSuccess: (data) => {
        const gpsString = `${data.lat},${data.lng}`;
        setGps(gpsString);
        form.setFieldValue(gpsName, gpsString);
        form.setFieldValue(addressName, data.address);
        setAddress(data.address);
        onChange({
          address: data.value.description,
          gps: data,
        });
      },
    });

  const { mutate: getAddressFromLatLng, isPending: isAddressPending } =
    useGetAddressFromLatLng({
      onSuccess: (data) => {
        setAddress(data);
        form.setFieldValue(addressName, data);
      },
    });

  const handleLocationCoordinated = (data) => {
    setMapModalVisible(false);
    const gpsString = `${data.lat},${data.lng}`;
    setGps(gpsString);
    form.setFieldValue(gpsName, gpsString);
    getAddressFromLatLng({ lat: data.lat, lng: data.lng });
  };

  return (
    <div>
      <Modal
        title={"Select Location"}
        open={mapModalVisible}
        maskClosable={false}
        width={800}
        onCancel={() => {
          setMapModalVisible(false);
          form.resetFields();
        }}
        style={{ top: 60 }}
        footer={true}
        zIndex={10}
      >
        <MapPicker setLocation={handleLocationCoordinated} />
      </Modal>

      <Row className="mt-4">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item name={addressName} label={"Address"}>
            <div
              className={`${
                enteringGps ? "disabled opacity-50 pointer-events-none" : ""
              }`}
            >
              <GooglePlaceAutoComplete
                defaultValue={enteringGps ? address : null}
                onSelect={(data) => {
                  setEnteringGps(false);
                  if (data) {
                    setEnteringAddress(true);
                    getCoordinatesFromAddress({
                      address: data.value.description,
                    });
                  } else {
                    setEnteringAddress(false);
                    setAddress(null);
                    form.setFieldValue(addressName, null);
                    form.setFieldValue(gpsName, null);
                    setGps(null);
                  }
                }}
              />
              {isCoordinatesPending && (
                <span className="ml-2">Fetching Coordinates...</span>
              )}
            </div>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item name={gpsName} label={"GPS"}>
            <Input
              disabled={enteringAddress}
              placeholder={gps}
              value={gps}
              suffix={
                <div className="flex gap-4">
                  {!enteringAddress && (
                    <FaLocationDot
                      style={{ cursor: "pointer", color: "red" }}
                      onClick={() => {
                        setEnteringAddress(false);
                        setMapModalVisible(true);
                        setEnteringGps(true);
                        if (onClickLocation) {
                          onClickLocation();
                        }
                      }}
                    />
                  )}
                  {gps && enteringGps == true && (
                    <FaWindowClose
                      style={{ cursor: "pointer", color: "red" }}
                      onClick={() => {
                        setGps(null);
                        setAddress(null);
                        setEnteringAddress(false);
                        setEnteringGps(false);
                        form.setFieldValue(gpsName, null);
                        form.setFieldValue(addressName, null);
                      }}
                    />
                  )}
                </div>
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}></Col>
      </Row>
    </div>
  );
};

export default AddressSelectionInput;
