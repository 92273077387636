import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { useState } from "react";

const DriverTripDropdown = ({
  titleOne,
  titleTwo,
  tonsCompleted = 0,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className="space-y-8">
      <div className="w-full bg-dark-bg rounded-[30px] flex items-center justify-between p-2 cursor-pointer">
        <div className="flex items-center gap-2">
          <div className="bg-[#DA6944] h-10 w-10 rounded-[50%]"></div>
          <div>
            <h1 className="font-semibold capitalize">
              {titleOne} {titleTwo && ` / ${titleTwo}`}
            </h1>
            <span className="">{tonsCompleted} tons Completed </span>
          </div>
        </div>

        <div className="mr-3" onClick={toggle}>
          {isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </div>
      </div>

      {isOpen && children}
    </div>
  );
};

export default DriverTripDropdown;
