import { Button } from "antd";
import useRequestPaymentSessionMutation from "./mutations";
import toastr from "toastr";

const ElavonPaymentButton = () => {
  const callback = {
    onError: function (error) {
      toastr.error(error);
    },
    onCancelled: function () {
      toastr.info("Payment cancelled");
    },
    onDeclined: function (response) {
      toastr.error(JSON.stringify(response, null, "\t"));
    },
    onApproval: function (response) {
      toastr.success(JSON.stringify(response, null, "\t"));
    },
  };

  const { mutate: requestPaymentSessionMutation } =
    useRequestPaymentSessionMutation({
      onSuccess: (token) => {
        initiatePayment(token, callback);
      },
    });

  const initiatePayment = (paymentFields, callback) => {
    if (window.PayWithConverge) {
      window.PayWithConverge.open(paymentFields, callback);
    } else {
      console.error("PayWithConverge.js is not loaded");
    }
  };

  return (
    <Button
      onClick={() => {
        requestPaymentSessionMutation({
          amount: "1.00",
          firstName: "John",
          lastName: "Smith",
        });
      }}
    >
      Pay
    </Button>
  );
};

export default ElavonPaymentButton;
