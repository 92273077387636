import { useMutation } from "@tanstack/react-query";
import { requestPaymentSessionToken } from "./services";

const useRequestPaymentSessionMutation = ({ options } = {}) => {
  return useMutation({
    mutationFn: requestPaymentSessionToken,
    mutationKey: ["requestPaymentSessionToken"],
    ...options,
  });
};

export default useRequestPaymentSessionMutation;
