import axios from "axios";

export const elavonApiIntance = axios.create({
  baseURL: "https://api.demo.convergepay.com/hosted-payments/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const requestPaymentSessionToken = async (payload) => {
  const { amount, firstName, lastName } = payload;

  const body = {
    ssl_transaction_type: "ccsale",
    ssl_account_id: "0033771",
    ssl_user_id: "apiuser669841",
    ssl_pin: "3JHG8BCND5EI0O6KF3HIAXOZEWI66SAVI3C6550E1LZNO0SKX9XEUVUHG0NE4MIV",
    ssl_amount: amount,
    ssl_first_name: firstName,
    ssl_last_name: lastName,
  };
  try {
    const response = await elavonApiIntance.post("/transaction_token", body);
    return response.data;
  } catch (error) {
    console.error("Error requesting payment session token:", error);
    throw error;
  }

  // const response = await apiHelper.post("/invoice?action=make_payment", body);
  // return response.data;
};
