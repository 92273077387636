import React, { useState } from "react";
import { Button, Form, Table } from "antd";
import { useNavigate } from "react-router-dom";
import useJobs from "../../api/queries/useJobs";
import Loader from "../../components/primitives/Loader";
import moment from "moment";
import { capitalise } from "../../utils/string";
import useCreateJobMutation from "../../api/mutations/userCreateJob";
import Toastr from "toastr";
import CreateJobFormModal from "../../components/modules/CreateJobModal";
import { invalidateQueries } from "../../api/queryClient";
import { Queries } from "../../api/queries";
import PaymentModal from "../../components/modules/Payment/PaymentModal.view";
import useMakePayment from "../../api/mutations/useMakePayment";

const LoadProjects = () => {
  const navigate = useNavigate();

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const [paymentPayload, setPaymentPayload] = useState({});

  const getColorByStatus = (status) => {
    const statusColors = {
      Hauling: "#00B336",
      assigned: "#6F55FF",
      Contracted: "#DA6944",
      pending_payment: "#e89a3c",
    };

    return statusColors[status] || "#000000";
  };

  const [createJobForm] = Form.useForm();
  const [createJobModalOpen, setCreateJobModalOpen] = useState(false);

  const { data: jobs, isLoading } = useJobs({
    assignedTo: "trucker",
  });

  const { mutate } = useCreateJobMutation({
    onSuccess: () => {
      invalidateQueries([Queries.jobs]);
      Toastr.success("Job Created successfully!");
      setCreateJobModalOpen(false);
    },
  });

  const { mutate: makePayment, isPending: isPaymentLoading } = useMakePayment({
    onSuccess: () => {
      invalidateQueries([Queries.jobs]);
      Toastr.success("Payment successful!");
      setPaymentModalOpen(false);
    },
  });

  const pay = () => {
    setPaymentModalOpen(true);
  };

  const submitCreateJob = (data) => {
    const payload = {
      form_input: {
        ...data.form_input,
      },
    };
    mutate(payload);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "job",
      key: "job",
      render: (job) => job.project_name,
    },
    {
      title: "Location",
      dataIndex: "job",
      key: "job",
      render: (job) =>
        job?.address?.length > 50
          ? `${job.address.substring(0, 50)}...`
          : job.address,
    },
    {
      title: "Start Date / Time",
      dataIndex: "job",
      key: "job",
      render: (job) => moment(Date(job.job_date_ts)).format("DD/MM/YYYY"),
    },
    {
      title: "Material",
      dataIndex: "job",
      key: "job",
      render: (job) => job.material,
    },
    {
      title: "Qty",
      dataIndex: "job",
      key: "job",
      render: (job) => job.quantity,
    },
    {
      title: "Remaining",
      dataIndex: "job",
      key: "job",
      render: (job) => job.quantity - (job.tons_completed ?? 0),
    },
    {
      title: "% Complete",
      dataIndex: "job",
      key: "job",
      render: (job) => {
        const totalQuantity = job.quantity;
        const completedQuantity = job.tons_completed ?? 0;
        const percentComplete = Math.round(
          (completedQuantity / totalQuantity) * 100
        );
        return (
          <div style={{ color: "#00B336" }}>
            {isNaN(percentComplete) ? "N/A" : `${percentComplete}%`}
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "job",
      render: (job) => {
        const haulingStatus = job.haul_status ?? job.job_status;
        switch (haulingStatus) {
          case "pending_payment":
            if (job.hasTruckerPaid == true && job.hasContractorPaid == false) {
              return "Contractor Payment Pending";
            }
            return (
              <Button
                onClick={() => {
                  setPaymentPayload({
                    job_id: job.job_id,
                    bid_id: job.bid_id,
                  });
                  pay();
                }}
                style={{
                  borderRadius: "20px",
                  backgroundColor: getColorByStatus(haulingStatus),
                  color: "#FFFFFF",
                }}
              >
                Make Payment
              </Button>
            );
          default:
            return (
              <Button
                style={{
                  borderRadius: "20px",
                  backgroundColor: getColorByStatus(haulingStatus),
                  color: "#FFFFFF",
                }}
                onClick={() => navigate(`/manage/projects/${job.job_id}`)}
              >
                {capitalise(haulingStatus)}
              </Button>
            );
        }
      },
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-end">
        <Button type="primary" onClick={() => setCreateJobModalOpen(true)}>
          Create New job
        </Button>
        <CreateJobFormModal
          form={createJobForm}
          visible={createJobModalOpen}
          onClose={() => {
            setCreateJobModalOpen(false);
            createJobForm.resetFields();
          }}
          onSubmit={(data) => {
            submitCreateJob(data);
          }}
        />
      </div>

      <PaymentModal
        amount={80}
        isSubmiting={isPaymentLoading}
        onSubmit={() => {
          makePayment(paymentPayload);
        }}
        visible={paymentModalOpen}
        onCancel={() => setPaymentModalOpen(false)}
      />
      <Table columns={columns} dataSource={jobs} />
    </div>
  );
};

export default LoadProjects;
