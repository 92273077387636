import { CaretDownOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button } from "antd";
import { TripStatus } from "../../enums/status";

const TripInfo = ({
  day,
  date,
  tonsCompleted,
  status,
  children,
  onClickInvoice,
  hideTonsComplete = false,
  hideStatus = false,
  hideInvoiceButton,
  gridCols = 2,
}) => {
  return (
    <div className="mt-4 space-y-2">
      <div className="flex justify-between">
        <div className="flex items-center gap-4">
          <CaretDownOutlined />
          <h1 className="font-bold text-lg">Day {day}</h1>
          <span className="font-normal">
            {moment(date).format("MM-DD-YYYY")}
          </span>
          {!hideTonsComplete && (
            <h1 className="font-bold text-lg">{tonsCompleted} Tons</h1>
          )}
          {!hideStatus && <h1 className="text-lg text-[#13B606]">{status}</h1>}
        </div>

        {!hideInvoiceButton && (
          <Button
            size="middle"
            onClick={onClickInvoice}
            style={{
              borderRadius: "5px",
              backgroundColor: "#6F55FF",
              color: "#FFFFFF",
            }}
          >
            Invoice
          </Button>
        )}
      </div>

      <div className="">
        <div className={`grid grid-cols-${gridCols} gap-4`}>{children}</div>
      </div>
    </div>
  );
};

export default TripInfo;
