import { Steps, Button, Modal } from "antd";

import CardWrap from "../../components/CardWrapper";
import { useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import React from "react";
import ManageProject from "../../components/modules/ManageProject/ManageProject.view";
import Toaster from "toastr";

import moment from "moment";
import useJob from "../../api/queries/useJob";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/primitives/Loader";
import { queryClient } from "../../api/queryClient";
import { Queries } from "../../api/queries";
import useHauls from "../../api/queries/useHauls";
import HaulAssignmentHelper from "../../helpers/haulAssignmentHelper";
import AssignmentHelper from "../../helpers/assignmentHelper";
import DriverTripDropdown from "../../components/primitives/DriverTripDropdown";
import TripInfo from "../../components/primitives/TripInfo";
import ProjectProgressInfo from "../../components/primitives/ProjectProgressInfo";
import useHaulUpdate from "../../api/mutations/useHaulUpdate";
import JobHelper from "../../helpers/jobHelper";
import $sessionStore from "../../store/session";
import Timer from "../../components/primitives/Timer";
import HourlyTripHelper from "../../helpers/hourlyTripHelper";
import { TripStatus } from "../../enums/status";

dayjs.extend(customParseFormat);

const customDot = () => <div className="dot"></div>;

const getColorByStatus = (status) => {
  const statusColors = {
    Hauling: "#00B336",
    Assigned: "#6F55FF",
    Unassigned: "#DA6944",
  };
  return statusColors[status] || "#000000";
};

//Create HandleJob Compoennt
function ProjectDetails() {
  const { company_id } = $sessionStore.actions.getUser();
  const { job_id } = useParams();
  const { data: job, isLoading, isFetched } = useJob(job_id);
  const navigate = useNavigate();

  const { mutate: updateHaul, isPending: isUpdating } = useHaulUpdate({
    onSuccess: () => {
      navigate(`/projects/completed`);
    },
  });

  const {
    data: hauls,
    refetch,
    isLoading: isHaulsLoading,
  } = useHauls({
    job_id,
    enabled: isFetched,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    if (jobData.isTransferLimitReached) {
      Toaster.error("Job initiation failed: Load transfer is fully completed.");
      return;
    }
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  dayjs.extend(customParseFormat);

  if (isLoading || isHaulsLoading) {
    return <Loader />;
  }

  const statuses = [
    {
      title: "Unassigned",
      description: "10/13/24 - 10/15/24 ",
    },
    {
      title: "Assigned",
    },
    {
      title: "Hauling",
    },
    {
      title: "Completed",
    },
    {
      title: "Deadline",
    },
  ];

  const jobData = new JobHelper(job);

  const isJobCompleted = jobData.isCompleted;
  const unitPrice = parseInt(jobData.bidById(jobData.bid_id).bid_amount || 0);
  const status = jobData.jobStatus;
  const isHourlyJob = jobData.isHourlyJob;

  const isContractorMaterialSource = jobData.isContractorMaterialSource;
  const { firstName = "", lastName = "" } = jobData.companyDetails || {};
  const fullName = `${firstName} ${lastName}`;

  const currentStatusIndex = (status) => {
    return statuses.findIndex((s) => s.title === status);
  };

  const completeProject = () => {
    const payload = {
      job_id,
      method: "completeHaul",
    };
    updateHaul(payload);
  };

  return (
    <div className="md:py-4 md:px-24 px-8  space-y-8">
      <div className="flex justify-between">
        <h1 className="text-2xl flex-shrink-0">
          Project: {jobData.projectName}
        </h1>

        <div className="flex items-center gap-4">
          <h1 className="text-2xl flex-shrink-0">Status: </h1>
          <Button
            type="primary"
            color="primary"
            style={{
              backgroundColor: getColorByStatus(status),
              color: "white",
            }}
            shape="round"
            size="large"
            className=" pointer-events-none"
          >
            {status}
          </Button>
        </div>
      </div>

      <Steps
        current={currentStatusIndex(status)}
        labelPlacement="horizontal"
        progressDot={customDot}
        items={statuses}
      />

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-32">
        <div>
          <div className="flex gap-4">
            <div className="flex-1 space-y-4">
              <CardWrap>
                <h4 className="font-semibold text-lg">
                  Date and Time of Delivery
                </h4>
                <div className="">
                  <h3 className="text-md font-semibold">Specific Date</h3>
                  <h3 className="text-grey-light">
                    {moment(jobData.job_date_ts).format("MM-DD-YYYY")}
                  </h3>
                </div>
              </CardWrap>

              <CardWrap>
                <h4 className="font-semibold text-lg">
                  Required Documentation
                </h4>
                <div className="">
                  <h3 className="text-md font-semibold">
                    There are 3 files uploaded
                  </h3>
                </div>
              </CardWrap>

              <CardWrap>
                <h4 className="font-semibold text-lg">Material</h4>
                <div className="">
                  <h3 className="text-md font-semibold">
                    Type of Rocks or Material
                  </h3>
                  <h3 className="text-grey-light">{jobData.materialType}</h3>
                </div>

                <div className="">
                  <h3 className="text-md font-semibold">
                    Total Amount of Rocks
                  </h3>
                  <h3 className="text-grey-light">
                    {jobData.totalMaterial} Tons
                  </h3>
                </div>
              </CardWrap>

              <CardWrap>
                <h4 className="font-semibold text-lg">Contact Information</h4>
                <div className="">
                  <h3 className="text-md font-semibold">Contact Person</h3>
                  <h3 className="text-grey-light">{fullName}</h3>
                </div>

                <div className="">
                  <h3 className="text-md font-semibold">Phone Number</h3>
                  <h3 className="text-grey-light">
                    {jobData.companyDetails.phoneNumber || "-"}
                  </h3>
                </div>
              </CardWrap>
            </div>
            <div className="flex-1 space-y-4">
              <CardWrap>
                <h4 className="font-semibold text-lg">Location</h4>
                {jobData.isGpsNotAvailable ? (
                  <h4 className="text-grey-light">
                    GPS Coordinates Not Available
                  </h4>
                ) : (
                  <iframe
                    title="map"
                    width="100%"
                    id="gmap_canvas"
                    className="rounded"
                    src={[
                      "https://maps.google.com/maps?q=",
                      jobData.gps,
                      "&t=&z=13&ie=UTF8&iwloc=&output=embed",
                    ].join("")}
                  ></iframe>
                )}

                <div className="">
                  <h3 className="text-md font-semibold">Construction Site</h3>
                  <h3 className="text-grey-light">{jobData.address.address}</h3>
                </div>
                <div className="">
                  <h3 className="text-md font-semibold">GPS Coordinates</h3>
                  <h3 className="text-grey-light">
                    {jobData.gpsCoordinates.lat}° N,{" "}
                    {jobData.gpsCoordinates.lng}° W
                  </h3>
                </div>
              </CardWrap>
              <CardWrap>
                <h4 className="font-semibold text-lg">Project Notes</h4>
                <div className="">
                  <h3 className="text-md font-semibold">
                    Special Requirements
                  </h3>
                  <h3 className="text-grey-light">
                    {jobData.specialInstructions}
                  </h3>
                </div>
              </CardWrap>
            </div>
          </div>
        </div>
        <div>
          <div className="space-y-4">
            {!isHourlyJob && (
              <ProjectProgressInfo
                percent={jobData.completedInPercent}
                total={jobData.totalMaterial}
                completed={jobData.completedTons}
              />
            )}

            {!jobData.isAssigned && (
              <Button
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#6F55FF",
                  color: "#FFFFFF",
                }}
                onClick={showModal}
              >
                Start Project
              </Button>
            )}

            {jobData.isAssigned &&
              hauls.map((haul, index) => {
                const haulData = new HaulAssignmentHelper(haul);
                const jobHelper = new JobHelper(haulData.job);
                const isHourlyJob = jobHelper.isHourlyJob;
                const tripInfo = {
                  day: hauls.length - index,
                  date: haulData.data.date,
                  tonsCompleted: haulData.tonsCompleted,
                  status: haulData.status,
                };

                let hideInvoice = true;
                let hoursWorked = 0;

                if (isHourlyJob) {
                  haulData.assignments.forEach((e) => {
                    const assignmentHelper = new AssignmentHelper(e);

                    const curTrip = assignmentHelper.getTheCurrentTrip({
                      isHourlyJob,
                    });

                    const hourlyTripHelper = new HourlyTripHelper(curTrip);
                    hoursWorked += hourlyTripHelper.calculateTotalHoursWorked();
                  });
                  if (hoursWorked > 0) {
                    hideInvoice = false;
                  }
                  if (haulData.status.toLowerCase() === TripStatus.COMPLETED) {
                    hideInvoice = true;
                  }
                } else {
                  hideInvoice = haulData.tonsCompleted > 0;
                  if (haulData.status.toLowerCase() !== TripStatus.COMPLETED) {
                    hideInvoice = false;
                  }
                }

                return (
                  <TripInfo
                    {...tripInfo}
                    gridCols={isHourlyJob ? 1 : 2}
                    hideTonsComplete={isHourlyJob}
                    hideInvoiceButton={hideInvoice}
                    onClickInvoice={() => {
                      const tonsCompleted = haulData.tonsCompleted;
                      const totalQuantity = haulData.job.quantity;
                      const totalPrice = unitPrice * tonsCompleted;
                      const totalHourlyPrice = unitPrice * hoursWorked;

                      const invoices = [
                        {
                          product: jobData.materialType,
                          hoursCompleted: hoursWorked,
                          totalQuantity: totalQuantity,
                          tonsCompleted: tonsCompleted,
                          total: isHourlyJob ? totalHourlyPrice : totalPrice,
                          unitPrice: unitPrice,
                        },
                      ];

                      navigate(
                        "/invoice/" +
                          jobData.bid_id +
                          "/" +
                          job_id +
                          "/" +
                          company_id,
                        {
                          state: {
                            isPartialInvoice: true,
                            invoices,
                            isHourlyJob,
                            invoiceHaulId: haulData.haulId,
                            hourlyJob: !isHourlyJob
                              ? null
                              : {
                                  hoursWorked,
                                  hourlyRate: unitPrice,
                                  invoices,
                                },
                            tonsCompleted: tonsCompleted,
                            material: jobData.materialType,
                            unitPrice: unitPrice,
                            totalPrice: totalPrice,
                            totalQuantity: totalQuantity,
                            haulIds: [haulData.haulId],
                          },
                        }
                      );
                    }}
                  >
                    {isHourlyJob &&
                      haulData.assignments.map((assignment) => {
                        const assignmentHelper = new AssignmentHelper(
                          assignment
                        );

                        const curTrip = assignmentHelper.getTheCurrentTrip({
                          isHourlyJob,
                        });

                        const hourlyTripHelper = new HourlyTripHelper(curTrip);

                        return (
                          <div className="flex items-center gap-4 border rounded-md px-4 py-2">
                            <span className="text-lg font-semibold capitalize">
                              {assignmentHelper.driverName}
                            </span>
                            <Timer
                              timerSize="sm"
                              initialSeconds={hourlyTripHelper.calculateEffectiveTripTime()}
                              pause={true}
                              className="text-lg font-semibold"
                            />
                            <span className="">
                              {hourlyTripHelper.calculateTotalHoursWorked()} Hrs
                            </span>

                            <span className="">
                              ${" "}
                              {(
                                hourlyTripHelper.calculateTotalHoursWorked() *
                                unitPrice
                              ).toFixed(2)}
                            </span>
                          </div>
                        );
                      })}

                    {!isHourlyJob &&
                      haulData.assignments.map((assignment) => {
                        const assignmentHelper = new AssignmentHelper(
                          assignment
                        );

                        return (
                          <DriverTripDropdown
                            titleOne={assignmentHelper.driverName}
                            tonsCompleted={assignmentHelper.totalLoadCompleted}
                          />
                        );
                      })}
                  </TripInfo>
                );
              })}

            <div className="">
              {isJobCompleted == true ? (
                <div className="flex justify-center gap-4 mt-24">
                  <div className="flex gap-4">
                    <div
                      disabled
                      className="rounded-[5px] bg-[#6F55FF] text-white px-2 py-1"
                    >
                      Job Complete
                    </div>
                  </div>
                </div>
              ) : (
                <div className="">
                  {jobData.isAssigned && (
                    <div className="flex justify-center gap-4 mt-24">
                      <Button
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#6F55FF",
                          color: "#FFFFFF",
                        }}
                        onClick={showModal}
                      >
                        Start New Day
                      </Button>
                      <Button
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#6F55FF",
                          color: "#FFFFFF",
                        }}
                        loading={isUpdating}
                        onClick={completeProject}
                      >
                        Complete Project
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>

            <Modal
              centered
              open={isModalOpen}
              footer={null}
              onCancel={handleCancel}
            >
              <ManageProject
                isContractorMaterialSource={isContractorMaterialSource}
                isHourlyJob={jobData.isHourlyJob}
                onSuccess={() => {
                  queryClient.invalidateQueries({
                    queryKey: [Queries.job],
                  });
                  refetch();
                  setIsModalOpen(false);
                }}
              />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetails;
