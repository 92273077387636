import { Layout, Table, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { API } from "../config/AppConstants";
import useSession from "../context/SessionContext";
import $ from "jquery";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import moment from "moment";

const Inbox = () => {
  const [selectedRow, setSelectedRow] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [businessName, setBusinessName] = useState([]);
  let { user } = useSession();
  const currentDate = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);
  const timestampThirtyDaysAgo = thirtyDaysAgo.getTime() / 1000;

  const columns = [
    {
      render: (record) => {
        return (
          <div>
            <div>
              From:{" "}
              <b>
                {businessName &&
                  businessName.find((r) => record.from === r.company_id) &&
                  businessName.find((r) => record.from === r.company_id)
                    .business_name}
              </b>
              <span style={{ float: "right" }}>
                <small>
                  {moment(new Date(record.modified_ts)).format(
                    "YYYY-MM-DD HH:mm"
                  )}
                </small>
              </span>
            </div>
            <div>Subject: {record.subject.substring(0, 25) + "..."}</div>
          </div>
        );
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
  ];

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotifications = async () => {
    if (!user) return;
    setLoading(true);
    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.INBOX.NOTIFICATIONS,
      type: "GET",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
    })
      .then(async (res) => {
        let notifications = res.data;
        // let businessDetails = await getBusinessNames();
        // setBusinessName(businessDetails);
        console.log(notifications, "notifications");
        setData(notifications);
        setLoading(false);
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (businessName.length > 0) {
      setLoading(false);
    }
  }, [businessName]);

  const getBusinessNames = () => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url:
          API.API_URL +
          API.API_VER +
          API.API_ENDPOINTS.BUSINESS_PRFL.BUSINESS_PRFL,
        type: "GET",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
      })
        .then((res) => {
          let businessPrfl = res.map((p) => {
            return { company_id: p.company_id, business_name: p.business_name };
          });
          resolve(businessPrfl);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          resolve(jqXHR);
        });
    });
  };

  const onCellSelected = (record) => {
    setSelectedRow(record);
  };

  const getMessageContent = (messageRec) => {
    return (
      <div>
        <div>
          From:{" "}
          {businessName &&
            businessName.find((r) => messageRec.from === r.company_id) &&
            businessName.find((r) => messageRec.from === r.company_id)
              .business_name}
          <span style={{ float: "right" }}>
            {moment(new Date(messageRec.modified_ts)).format(
              "YYYY-MM-DD HH:mm:ss"
            )}
          </span>
        </div>
        <div
          style={{ marginTop: "10px", fontSize: "15px" }}
          className="inbox-header"
        >
          {" "}
          Subject: {messageRec.subject}{" "}
        </div>
        <div style={{ border: "0.5px solid #5d6c83", marginTop: "10px" }}></div>
        <div
          style={{
            marginTop: "20px",
            lineHeight: "200%",
            fontSize: "15px",
            whiteSpace: "pre-line",
          }}
        >
          {" "}
          <div dangerouslySetInnerHTML={{ __html: messageRec.message }} />{" "}
        </div>
      </div>
    );
  };

  let isMobile = window.innerWidth <= 600;

  if (
    !isMobile &&
    (!selectedRow || Object.keys(selectedRow).length === 0) &&
    data.length > 0
  ) {
    setSelectedRow(data[0]);
  }

  return (
    <Layout style={isMobile ? { padding: "0px" } : { margin: "10px" }}>
      {!loading ? (
        <React.Fragment>
          <Sider
            width={isMobile ? "100%" : "30%"}
            style={{
              backgroundColor: "rgba(10, 10, 10, 0.5)",
              height: "80vh",
              overflowY: "auto",
            }}
          >
            {data.length > 0 ? (
              <Table
                rowKey={(record) => record.thread_id}
                columns={columns}
                height="80vh"
                dataSource={data}
                showHeader={false}
                pagination={false}
                rowClassName={(record, index) => {
                  return selectedRow.thread_id === record.thread_id
                    ? "highlight-row-msg cursor-pointer"
                    : "cursor-pointer";
                }}
                onRow={
                  isMobile
                    ? null
                    : (record) => ({
                        onClick: () => {
                          onCellSelected(record);
                        },
                      })
                }
                expandable={
                  isMobile && {
                    expandedRowKeys: [selectedRow.thread_id],
                    expandedRowRender: (record) => {
                      return <div>{getMessageContent(record)}</div>;
                    },
                    onExpand: (expanded, record) => {
                      if (expanded) {
                        setSelectedRow(record);
                      } else {
                        setSelectedRow({});
                      }
                    },
                  }
                }
              />
            ) : (
              <div style={{ paddingTop: "300px", fontSize: "20px" }}>
                <center>Inbox is empty</center>
              </div>
            )}
          </Sider>
          {!isMobile && (
            <Layout style={{ background: "#4a5668" }}>
              {Object.keys(selectedRow).length > 0 ? (
                <Content
                  style={{
                    minHeight: 200,
                    background: "#4a5668",
                    padding: "10px 20px",
                  }}
                >
                  <div
                    className="inbox-container"
                    style={{ paddingTop: "10px", minHeight: "360" }}
                  >
                    {getMessageContent(selectedRow)}
                  </div>
                </Content>
              ) : (
                <Content style={{ background: "#4a5668" }}>
                  {data.length > 0 && (
                    <div
                      style={{
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh",
                        margin: "0",
                      }}
                    >
                      {" "}
                      Select an item to read{" "}
                    </div>
                  )}
                </Content>
              )}
            </Layout>
          )}
        </React.Fragment>
      ) : (
        <div className="center-container">
          <Spin tip="Loading"></Spin>
        </div>
      )}
    </Layout>
  );
};

export default Inbox;
